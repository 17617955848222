<template>
  <div class="box-container">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        xl="12"
        lg="12"
      >
        <div class="d-flex justify-space-between flex-wrap mb-5">
          <div class="d-flex align-center ">
            <v-avatar
              tile
              size="25"
              class="me-3"
            >
              <img
                src="@/assets/images/icons/user_filled.svg"
                alt=""
              >
            </v-avatar>
            <h2 class="mb-0">
              {{ $t('Mon Profile') }}
            </h2>
          </div>
          <v-btn
            to="/edit-profile"
            outlined
            color="primary"
            class="text-capitalize font-600"
          >
            {{ $t("Modifier le profil") }}
          </v-btn>
        </div>
        <v-row>
          <v-col cols="12">
            <photo-widget />
          </v-col>
          <v-col
            v-if="!loadingStatistics && user && (user.is_pro_seller || user.is_seller)"
            cols="12"
          >
            <v-row>
              <v-col cols="12">
                <v-alert
                  v-if="user.status == 2"
                  type="warning"
                  dismissible
                >
                  {{ $t("Votre boutique est en pause le produit ne sont pas visible par les utilisateurs!") }}
                  <v-btn
                    v-if="user.status == 2"
                    to="users.changestatusshop/1"
                  >
                    {{ $t("Réactiver ma boutique") }}
                  </v-btn>
                </v-alert>

                <v-alert
                  v-if="statistics.seller_active_promotions"
                  type="warning"
                  dismissible
                >
                  {{ $t("Votre Boutique est mise en Vedette seulement sur La Page d'Accueil") }}

                  <v-btn
                    to="/shop.promotionform/1"
                    class="ml-2"
                  >
                    {{ $t("Voir les promotions") }}
                  </v-btn>
                </v-alert>
                <v-alert
                  v-else
                  type="warning"
                  dismissible
                >
                  {{ $t("Votre boutique n'est pas en vedette!") }}

                  <v-btn
                    to="/promotions"
                    class="ml-2"
                  >
                    {{ $t("Mettre en promotion") }}
                  </v-btn>
                </v-alert>
              </v-col>
            </v-row>
            <shop-dashboard :statistics="statistics" />
          </v-col>
          <v-col
            v-if="!loadingStatistics"
            cols="12"
          >
            <v-row v-if="!loadingStatistics && user && !(user.is_pro_seller || user.is_seller)">
              <v-col
                cols="6"
                sm="6"
                md="3"
              >
                <router-link
                  to="/orders"
                  class="text-decoration-none"
                >
                  <base-card
                    class="h-100 pa-4 d-flex flex-column text-center align-center justify-center"
                    to="/orders"
                  >
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.buyer_orders }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Commandes") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="3"
              >
                <router-link
                  to="/orders"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.buyer_parcels }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Colis") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="3"
              >
                <router-link
                  to="/user/packages"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.buyer_parcels_for_sending }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("En attente d'expédition") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="3"
              >
                <router-link
                  to="/orders"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.buyer_parcels_for_delivery }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("En attente de livraison") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="!loadingStatistics && user && (user.is_pro_seller || user.is_seller)"
            cols="12"
          >
            <v-row>
              <v-col
                cols="6"
                sm="6"
                :md="statistics.association?'3':'4'"
              >
                <router-link
                  to="/products"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.seller_products }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Produits") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :md="statistics.association?'3':'4'"
              >
                <router-link
                  to="/promotions"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.seller_promotions }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Promotions") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :md="statistics.association?'3':'4'"
              >
                <router-link
                  to="/transporters"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.seller_transporters }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Transporteurs") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <v-col
                v-if="statistics.association"
                cols="6"
                sm="6"
                md="3"
              >
                <router-link
                  to="/association.payoutView/associationID"
                  class="text-decoration-none"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      {{ statistics.association.wallet_amount }}/{{ statistics.association.amount }}
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      {{ $t("Association") }}
                    </p>
                  </base-card>
                </router-link>
              </v-col>
              <!--
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="circle-tile ">
                  <a href="{{route("sellers.packages")}}">
                  <div class="circle-tile-heading dark-gray">
                    <i class="fa fa-shopping-cart fa-fw fa-3x"></i></div>
                  </a>
                  <div class="circle-tile-content dark-gray">
                    <div
                      class="circle-tile-description text-faded"> {{t("Colis à expédier")}}</div>
                    <div class="circle-tile-number text-faded ">
                      {{App\Package::where("seller_id", $user->id)->count()}} </div>
                    <a class="circle-tile-footer"
                       href="{{route("sellers.packages")}}">{{t("Plus d'informations")}}
                    <i class="fa fa-chevron-circle-right"></i></a>
                  </div>
                </div>
              </div>-->
              <!--
              <div hidden class="col-lg-4 col-md-4 col-sm-6">
                <div class="circle-tile ">
                  <a href="{{route("users.sales")}}">
                  <div class="circle-tile-heading dark-gray">
                    <i class="fa fa-shopping-cart fa-fw fa-3x"></i></div>
                  </a>
                  <div class="circle-tile-content dark-gray">
                    <div class="circle-tile-description text-faded"> {{t("Ventes")}}</div>
                    <div class="circle-tile-number text-faded ">
                      &euro;{{Auth::guard("web")->user()->sales()->where("status",">",1)->count()}}</div>
                    <a class="circle-tile-footer"
                       href="{{route("users.sales")}}">{{t("Plus d'informations")}}
                    <i class="fa fa-chevron-circle-right"></i></a>
                  </div>
                </div>
              </div>
-->
            </v-row>
            <v-row
              v-if=" user && user.status != 2"
              justify="center"
              class="justify-center align-content-center align-center"
            >
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  to="users.changestatusshop/2"
                  :title="$t('Vos produits ne seront plus visible!')"
                >
                  {{ $t("Mettre ma boutique en pause") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="loadingStatistics"
            cols="12"
            class="text-center"
          >
            <v-progress-circular indeterminate />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DashbordSidebar from '@/components/DashboardSidebar'
  import { mapState } from 'vuex'

  import { formatDate } from '@/core/utils/filter'
  import ProfileMenu from '../../components/ProfileMenu.vue'
  import PhotoWidget from './PhotoWidget.vue'
  import Dashboard from '../Dashboard.vue'
  import ShopDashboard from '../vendor/ShopDashboard.vue'
  export default {
    components: {
      ShopDashboard,
      PhotoWidget,
    },
    data () {
      return {
        isSidebar: false,
        loadingStatistics: false,
        statistics: {
          buyer_orders: 0,
          buyer_parcels: 0,
          buyer_parcels_for_sending: 0,
          buyer_parcels_for_delivery: 0,
          seller_parcels_for_delivery: 0,
          seller_products: 0,
          seller_promotions: 0,
          seller_active_promotions: 0,
          seller_transporters: 0,
          seller_revenue: 0,
          seller_balance: 0,
          seller_sales: [],
        },

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
      scrollHeight () {
        return (this.windowHeight - 48) + 'px'
      },
    },
    created () {
      this.getStatistics()
    },
    methods: {
      getStatistics () {
        this.loadingStatistics = true
        // eslint-disable-next-line no-undef
        axios.get('/statistics').then((response) => {
          this.loadingStatistics = false
          if (response.data) {
            this.statistics = response.data
          }
        }).then((error) => {

        })
      },
    },

  }

</script>
